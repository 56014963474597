<template>
  <div class="collaborator-container">
    <h2 class="section-title">
      Add Collaborators
    </h2>
    <transition-group name="fade">
      <div v-for="user in collaborators" :key="user.uid" class="collaborator">
        <div class="row">
          <div class="col-md-7 col-xs-12">
            <div class="name instruction">{{ user.displayName }}</div>
            <div class="email">{{ user.email }}</div>
          </div>
          <div class="col-md-3 col-xs-8 bottom-xs">
            <label>
              <v-select
                :value="user.role"
                :options="Object.values(EVENT_ROLES)"
                :clearable="false"
                :searchable="false"
                @input="onRoleChanged(user, $event)"
              ></v-select>
            </label>
          </div>
          <div class="col-md-2 col-xs-4 end-xs">
            <button @click="onRemoveCollaborator(user)" class="secondary icon">
              delete<label>Remove</label>
            </button>
          </div>
        </div>
      </div>
    </transition-group>
    <div class="collaborator">
      <div class="row">
        <div class="col-md-7 col-xs-12">
          <div class="input">
            <input
              type="text"
              :value="form.email"
              @input="updateForm('email', $event.target.value)"
              placeholder="Enter email"
            />
          </div>
        </div>
        <div class="col-md-3 col-xs-8">
          <label>
            <v-select
              :value="form.role"
              @input="updateForm('role', $event)"
              :options="Object.values(EVENT_ROLES)"
              :clearable="false"
              :searchable="false"
              :disabled="!isEmailValid"
            />
          </label>
        </div>
        <div class="col-md-2 col-xs-4 end-xs">
          <button
            @click="onAddCollaborator"
            class="secondary"
            :disabled="!isEmailValid"
          >
            + Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../styles/variables";
@import "../../styles/mixins";

.collaborator-container {
  .collaborator {
    font-size: 1.5rem;
    padding: 1em 0;
    &:not(:first-child) {
      border-top: 1px solid $line-color;
    }

    .name {
      font-size: 1.2rem;
      padding-bottom: 0.5rem;
    }

    .name,
    .email {
      @include truncate;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import { validateEmail } from "../../utils";
import { getErrorMessage } from "../../utils/errors";
import AppModal from "../shared/AppModal";
import { EVENT_ROLES } from "../../../functions/lib/constants";

export default {
  props: ["user", "event", "collaborators"],
  data() {
    return {
      EVENT_ROLES,
      form: {
        email: "",
        role: EVENT_ROLES.MODERATOR
      }
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.form.email);
    }
  },
  mounted() {
    this.fetchCollaborators(this.event);
  },
  methods: {
    ...mapActions([
      "fetchCollaborators",
      "updateCollaborator",
      "removeCollaborator"
    ]),
    isMe(user) {
      return this.user.uid === user.uid;
    },
    isRoleModifed(user) {
      return user.role !== this.collaborators[user.uid];
    },
    updateForm(field, value) {
      this.form[field] = value;
    },
    async onRoleChanged(user, role) {
      if (this.isMe(user) && role !== EVENT_ROLES.OWNER) {
        this.$modal.show(AppModal, {
          title: "Are you sure you want to revoke ownership?",
          text: "You will no longer be able to manage this event.",
          buttons: [
            {
              title: "Cancel",
              class: "secondary"
            },
            {
              title: "Confirm",
              handler: modal => {
                modal.$emit("close");
                this.confirmRoleChange(user, role);
              }
            }
          ]
        });
      } else {
        this.confirmRoleChange(user, role);
      }
    },
    async confirmRoleChange(user, role) {
      try {
        await this.updateCollaborator({
          eventId: this.event.id,
          uid: user.uid,
          role: role
        });
      } catch (err) {
        this.$modal.show(AppModal, getErrorMessage(err));
      }
    },
    async onAddCollaborator() {
      try {
        await this.updateCollaborator({
          eventId: this.event.id,
          email: this.form.email,
          role: this.form.role
        });
        this.form.email = "";
        this.form.role = EVENT_ROLES.MODERATOR;
      } catch (err) {
        this.$modal.show(AppModal, getErrorMessage(err));
      }
    },
    onRemoveCollaborator(user) {
      let name = this.isMe(user) ? "yourself" : user.displayName;
      let subject = this.isMe(user) ? "You" : "They";

      this.$modal.show(AppModal, {
        title: `Are you sure you want to remove ${name}?`,
        text: `${subject} will no longer have access to this event.`,
        buttons: [
          {
            title: "Cancel",
            class: "secondary"
          },
          {
            title: "Remove",
            handler: modal => {
              modal.$emit("close");
              this.confirmRemoveCollaborator(user);
            }
          }
        ]
      });
    },
    async confirmRemoveCollaborator(user) {
      try {
        await this.removeCollaborator({
          eventId: this.event.id,
          uid: user.uid
        });
        if (this.isMe(user)) {
          this.$router.push("/events");
        }
      } catch (err) {
        this.$modal.show(AppModal, getErrorMessage(err));
      }
    }
  }
};
</script>
