<template>
  <div class="event-list-container">
    <div class="wrapper">
      <header class="row">
        <div class="col-sm-7 col-xs-10">
          <h2>Your Events</h2>
        </div>
        <div class="col-sm-5 col-xs-2 end-xs">
          <button
            class="icon hidden-sm hidden-md hidden-lg hidden-xl"
            @click="onCreateEventClicked"
          >
            add<label>Create Event</label>
          </button>
          <button class="hidden-xs" @click="onCreateEventClicked">
            Create Event
          </button>
        </div>
      </header>
      <transition name="fade" mode="out-in">
        <div
          v-if="!eventsLoading && events.length === 0"
          key="no-events"
          class="no-events"
        >
          <p>No events found...</p>
          <button class="tertiary" @click="onCreateEventClicked">
            + Create new event
          </button>
        </div>
        <div v-else-if="events.length > 0" key="event-list" class="event-list">
          <event-list-item
            v-for="event in events"
            :key="event.id"
            :event="event"
            :user="user"
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="eventsLoading" class="loading-container">
          <app-spinner />
        </div>
      </transition>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.event-list-container {
  @include full-height;
  display: flex;
  flex-direction: column;
  .wrapper {
    header {
      padding: 20px 0;
      border-bottom: 2px solid $line-color;
      h2 {
        margin: 0;
      }
    }
  }

  .no-events {
    text-align: center;
    padding: 100px 0;
  }

  .loading-container {
    background: transparent;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import EventListItem from "../components/event/EventListItem";
import AppSpinner from "../components/shared/AppSpinner";

export default {
  components: {
    AppSpinner,
    EventListItem
  },
  data() {
    return {
      eventsLoading: true
    };
  },
  computed: {
    ...mapGetters(["user", "events"])
  },
  async mounted() {
    await this.fetchUserEvents(this.user.uid);
    this.eventsLoading = false;
  },
  methods: {
    ...mapActions(["fetchUserEvents", "createEvent"]),
    async onCreateEventClicked() {
      const { uid } = this.user;
      try {
        await this.createEvent(uid).then(event => {
          this.$router.push(`/${event.id}/edit`);
        });
      } catch (err) {
        alert(err);
      }
    }
  }
};
</script>
