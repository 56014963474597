<template>
  <div class="event-container moderate">
    <prompt :currentPrompt="currentPrompt" :prompts="prompts">
      <button class="secondary pull-right" @click="resetSession()">
        Reset Session
      </button>
    </prompt>
    <tensions :currentPrompt="currentPrompt" viewerType="moderator"></tensions>
    <simulation
      :participants="participants"
      :user="user"
      :raisedHands="raisedHands"
      :speaker="currentSpeaker"
      @participantClicked="setSpeaking"
    >
      <countdown
        :time="countdownTime"
        @complete="setPromptState(PROMPT.SHOW_ALL)"
      />
    </simulation>
    <overlay
      :state="sessionState"
      :event="event"
      :participants="participants"
    ></overlay>
    <div v-if="sessionState === EVENT.INITIAL" class="action-container wrapper">
      <button class="pull-right" @click="startSession()">
        Start Session
      </button>
    </div>
    <div
      v-if="sessionState === EVENT.INTERSTITIAL"
      class="action-container wrapper"
    >
      <button class="pull-right" @click="revealPrompt()">
        Reveal Prompt
      </button>
    </div>
    <div v-if="sessionState === EVENT.ACTIVE" class="action-container wrapper">
      <button
        class="secondary"
        :disabled="currentPromptIdx === 0"
        @click="prevPrompt()"
      >
        Previous Prompt
      </button>
      <div class="state">
        <div class="current">
          <label>Currently showing:</label>{{ currentPrompt.state }} view
        </div>
        <button
          v-if="currentPrompt.state === PROMPT.SHOW_ALL"
          class="secondary"
          @click="setPromptState(PROMPT.SHOW_INDIVIDUAL)"
        >
          Show Individual
        </button>
        <button
          v-if="currentPrompt.state === PROMPT.SHOW_INDIVIDUAL"
          class="secondary"
          @click="setPromptState(PROMPT.SHOW_ALL)"
        >
          Show All
        </button>
        <button
          v-if="currentPrompt.state === PROMPT.CLOSED"
          class="secondary"
          @click="setPromptState(PROMPT.SHOW_ALL)"
        >
          Unlock
        </button>
      </div>
      <button
        v-if="currentPromptIdx < prompts.length - 1"
        @click="nextPrompt()"
      >
        Next Prompt
      </button>
      <button
        v-if="currentPromptIdx === prompts.length - 1"
        @click="finishSession()"
      >
        Finish Session
      </button>
    </div>
    <div
      v-if="sessionState === EVENT.FINISHED"
      class="action-container wrapper"
    >
      <button class="secondary" @click="prevPrompt()">
        Previous Prompt
      </button>
      <button class="secondary pull-right" @click="resetSession()">
        Reset Session
      </button>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.event-container {
  .state {
    flex-direction: row;
    display: flex;
    align-items: center;
    .current {
      @include upper;
      text-align: right;
      padding-right: 20px;
      label {
        font-family: $serif;
        text-transform: none;
      }
    }
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import Session, { PROMPT, EVENT } from "../api/session";
import { logEventStart, logEventFinish } from "../api/analytics";
import Simulation from "../components/Simulation";
import Prompt from "../components/Prompt";
import Tensions from "../components/Tensions";
import Countdown from "../components/Countdown";
import Overlay from "../components/Overlay";

export default {
  components: {
    Simulation,
    Prompt,
    Tensions,
    Overlay,
    Countdown
  },
  data() {
    return {
      PROMPT,
      EVENT
    };
  },
  computed: {
    ...mapGetters([
      "user",
      "prompts",
      "event",
      "participants",
      "countdownTime",
      "sessionState",
      "currentPromptIdx",
      "currentPrompt",
      "raisedHands",
      "currentSpeaker"
    ])
  },
  async created() {
    try {
      const { eventId } = this.$route.params;
      this.session = new Session(eventId);
      this.session.onParticipantAdded(this.addParticipant);
      this.session.onParticipantRemoved(this.removeParticipant);
      this.session.onParticipantChanged(this.updateParticipant);
      this.session.onSessionChanged(this.updateSession);
    } catch (err) {
      // console.error(err);
    }
  },
  beforeDestroy() {
    this.session.unsubscribe();
  },
  methods: {
    ...mapActions([
      "updateEvent",
      "updateSession",
      "addParticipant",
      "removeParticipant",
      "updateParticipant"
    ]),
    resetSession() {
      const res = confirm("Are you sure you want to reset the session?");
      if (res) {
        this.session.reset();
      }
    },
    startSession() {
      this.session.start();
      logEventStart({
        event: this.event,
        participants: this.participants
      });
    },
    revealPrompt() {
      this.session.revealPrompt();
    },
    nextPrompt() {
      if (this.currentPromptIdx < this.prompts.length - 1) {
        this.session.nextPrompt();
      }
    },
    prevPrompt() {
      if (this.currentPromptIdx != 0) {
        this.session.prevPrompt();
      }
    },
    setSpeaking(participant, event) {
      if (event) {
        event.stopPropagation();
      }
      this.session.setSpeaking(participant);
    },
    setPromptState(state) {
      this.session.setPromptState(this.currentPrompt.id, state);
    },
    finishSession() {
      this.session.setState(EVENT.FINISHED);
      logEventFinish({
        event: this.event,
        participants: this.participants
      });
    }
  }
};
</script>
