<template>
  <div class="prompt-edit-container">
    <div class="row">
      <div class="col-lg-8 col-md-10 col-xs-12">
        <label>
          Prompt
          <div class="input">
            <textarea
              rows="1"
              type="text"
              :value="ellipsisPromptPrompt"
              @input="onPromptChanged('prompt', $event.target.value)"
              @focus="showEllipsis = false"
              @blur="showEllipsis = true"
              placeholder="Add prompt"
            /></div
        ></label>
      </div>
      <div
        class="prompt-edit-actions col-lg-4 col-md-2 col-xs-12 end-xs first-xs initial-order-md"
      >
        <button @click="onDelete" class="icon" aria-label="Delete this prompt">
          delete<label>Delete</label>
        </button>
        <div class="order-controls">
          <div class="arrow dec" @click="onReorder(-1)">▲</div>
          <div class="arrow inc" @click="onReorder(1)">▼</div>
        </div>
      </div>
    </div>
    <div class="row top-xs">
      <div class="col-xs-12 col-lg-4 col-md-6 sm-right-pad">
        <label>
          Tension Left
          <div class="input">
            <textarea
              rows="1"
              type="text"
              :value="form.tension1"
              @input="onPromptChanged('tension1', $event.target.value)"
              placeholder="Add answer"
            />
          </div>
        </label>
      </div>
      <div class="col-xs-12 col-lg-4 col-md-6">
        <label
          >Tension Right
          <div class="input">
            <textarea
              rows="1"
              type="text"
              :value="form.tension2"
              @input="onPromptChanged('tension2', $event.target.value)"
              placeholder="Add answer"
            /></div
        ></label>
      </div>
      <div class="col-xs-12 col-lg-2 col-md-6">
        <label aria-label="Prompt duration in seconds">
          Countdown
          <div class="input">
            <input
              class="countdown"
              type="number"
              @input="onPromptChanged('countdown', $event.target.value)"
              :value="form.countdown"
            />
            <div class="input-annotation">seconds</div>
          </div>
        </label>
      </div>
      <div class="col-xs-8 col-lg-2 col-md-4">
        <label>
          Type
          <v-select
            :value="form.type"
            @input="onPromptChanged('type', $event, false)"
            :options="promptTypes"
            :clearable="false"
            :searchable="false"
          />
        </label>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
.event-edit-container {
  .prompt-edit-container {
    padding: 30px 0px 10px;
    border-bottom: 1px solid $line-color;
    &:last-of-type {
      margin-bottom: 30px;
    }
    .countdown {
      display: inline-block;
      width: 3rem;
    }
    .prompt-edit-actions {
      display: flex;
      align-items: center;
    }
    .order-controls {
      padding-left: 10px;
      margin-right: -5px;
      color: $primary;

      .arrow {
        cursor: pointer;
        padding: 5px;

        &:hover {
          color: $primary-darker;
        }
      }
    }
  }
}
</style>
<script>
import { PROMPT_TYPES, PROMPT_DEFAULT_COUNTDOWNS } from "../api/prompts";
import autosize from "autosize";

export default {
  props: ["prompt"],
  data: function() {
    return {
      form: {
        prompt: null,
        tension1: null,
        tension2: null,
        countdown: null,
        type: null
      },
      showEllipsis: true,
      promptTypes: Object.values(PROMPT_TYPES)
    };
  },
  created() {
    Object.keys(this.form).forEach(key => {
      this.form[key] = this.prompt[key];
    });
  },
  mounted() {
    autosize(this.$el.querySelectorAll("textarea"));
  },
  computed: {
    ellipsisPromptPrompt() {
      let { prompt = "" } = this.form;
      prompt = prompt.replace(/…/g, "");
      if (this.showEllipsis) {
        return prompt.length ? prompt + "…" : "";
      } else {
        return prompt;
      }
    }
  },
  methods: {
    onPromptChanged(field, value) {
      const updates = {};
      updates[field] = value;
      if (field === "type") {
        updates.countdown = PROMPT_DEFAULT_COUNTDOWNS[value];
      }
      this.form = Object.assign({}, this.form, updates);
      this.$nextTick(() => {
        autosize.update(this.$el.querySelectorAll("textarea"));
      });
      this.$emit("update", this.prompt, updates);
    },
    onReorder(direction) {
      this.$emit("reorder", this.prompt, direction);
    },
    onDelete() {
      this.$emit("delete", this.prompt);
    }
  }
};
</script>
