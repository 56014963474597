<template>
  <div class="event-edit-container">
    <transition name="fade">
      <div class="loading-container" v-if="promptsLoading">
        <app-spinner />
      </div>
    </transition>
    <div class="wrapper" v-if="!promptsLoading">
      <div class="section-title sticky">
        <div class="row">
          <div class="col-xs-6">
            <h2>
              {{ editorTitle }}
            </h2>
            <label class="notification" v-show="isUpdated && !isSaved">
              <app-spinner /> Auto-saving...
            </label>
            <label class="notification" v-show="isSaved">
              ✔ Changes saved
            </label>
          </div>
          <div class="col-xs-6 end-xs">
            <router-link
              :to="moderateLink"
              :disabled="!form.name || form.name.length == 0"
              class="btn primary"
            >
              Moderate
            </router-link>
          </div>
        </div>
      </div>
      <div class="event-edit-details">
        <div class="row">
          <div class="col-lg-8 col-xs-12">
            <label>
              Event Name
              <div class="input">
                <textarea
                  type="text"
                  ref="name"
                  :value="form.name"
                  @input="onEventChanged('name', $event.target.value)"
                  rows="1"
                  placeholder="Add event name"
                />
              </div>
            </label>
          </div>
        </div>
        <div class="row middle-xs">
          <div class="col-lg-8 col-xs-12">
            <label>
              Event Link
            </label>
            <div class="input-annotation">
              <router-link :to="eventLink" class="event-link">
                {{ eventLinkFull }}
              </router-link>
              <button
                @click="copyLink"
                :disabled="!form.name || form.name.length == 0"
                class="icon"
                aria-label="Copy event link to clipboard"
              >
                copy<label>Copy link</label>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="prompts-container">
        <transition-group name="fade" tag="div">
          <prompt-edit
            v-for="prompt in prompts"
            :key="prompt.id"
            :prompt="prompt"
            @update="onPromptUpdate"
            @reorder="onPromptReorder"
            @delete="onPromptDelete"
          />
        </transition-group>
        <div class="end-xs">
          <button @click="addPrompt" class="secondary">
            Add Prompt
          </button>
        </div>
      </div>
      <collaborator-edit
        :user="user"
        :event="event"
        :collaborators="collaborators"
      />
      <div class="settings-container">
        <h2 class="section-title">Delete Event</h2>
        <div class="settings-section">
          <div class="row">
            <div class="col-md-8">
              <p>
                This will delete the entire event and associated data. This
                action cannot be undone.
              </p>
            </div>
            <div class="col-md-4 end-xs">
              <button @click="onDeleteEventClicked" class="secondary">
                Delete Event
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../styles/variables";
@import "../styles/mixins";

.event-edit-container {
  @include full-height;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;

  .wrapper {
    padding-bottom: 2em;
  }

  .section-title.sticky {
    background: $black;
    box-shadow: 0 0 30px 25px $black;
  }

  .event-edit-details,
  .prompts-container,
  .settings-section {
    padding: 20px 0;
  }

  .event-edit-details {
    border-bottom: 1px solid $line-color;
  }

  .event-link {
    margin-right: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $grey;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }

  .v-select {
    padding-top: $form-padding;
    margin-bottom: 20px;
  }
}
</style>
<script>
import { mapGetters, mapActions } from "vuex";
import PromptEdit from "../components/PromptEdit";
import CollaboratorEdit from "../components/edit/CollaboratorEdit";
import AppModal from "../components/shared/AppModal";
import { copyTextToClipboard, throttle } from "../utils";
import autosize from "autosize";
import AppSpinner from "../components/shared/AppSpinner";

export default {
  components: {
    PromptEdit,
    CollaboratorEdit,
    AppSpinner
  },
  data() {
    return {
      form: {
        name: null
      },
      isUpdated: false,
      isSaved: false
    };
  },
  created() {
    this.updateEventThrottled = throttle(this._updateEvent, 1000, {
      leading: false
    });
    this.updatePromptThrottled = throttle(this._updatePrompt, 1000, {
      leading: false
    });
    this.markSaved = throttle(this._markSaved, 400);
    this.form = Object.assign({}, this.form, this.event);
  },
  mounted() {
    autosize(this.$refs.name);
  },
  computed: {
    ...mapGetters([
      "user",
      "event",
      "collaborators",
      "prompts",
      "promptsLoading"
    ]),
    baseUrl() {
      return window.location.origin;
    },
    eventLink() {
      return `/${this.event.id}`;
    },
    eventLinkFull() {
      return this.baseUrl + this.eventLink;
    },
    moderateLink() {
      return `/${this.event.id}/moderate`;
    },
    editorTitle() {
      return !this.form.name ? "Create New Event" : "Edit Event";
    }
  },
  methods: {
    ...mapActions([
      "updateEvent",
      "removeEvent",
      "createPrompt",
      "updatePrompt",
      "deletePrompt",
      "reorderPrompts"
    ]),
    async _updateEvent(updates) {
      await this.updateEvent(updates);
      this.markSaved(true);
    },
    async _updatePrompt(updates) {
      await this.updatePrompt(updates);
      this.markSaved(true);
    },
    _markSaved(value) {
      this.isUpdated = true;
      this.isSaved = value;
    },
    onEventChanged(field, value) {
      this.form[field] = value;
      autosize.update(this.$refs.name);

      this.markSaved(false);
      this.updateEventThrottled({
        id: this.event.id,
        [field]: value
      });
    },
    onPromptUpdate(prompt, updates) {
      this.markSaved(false);
      this.updatePromptThrottled({ id: prompt.id, ...updates });
    },
    async onPromptReorder(prompt, direction) {
      const curIdx = prompt.idx;
      let nextIdx = curIdx + direction;
      if (nextIdx < 0 || nextIdx === this.prompts.length) {
        return;
      }
      const from = this.prompts[curIdx];
      const to = this.prompts[nextIdx];
      this.markSaved(false);
      await this.reorderPrompts({ from, to });
      this.markSaved(true);
    },
    async onPromptDelete(prompt) {
      if (prompt.prompt) {
        this.$modal.show(AppModal, {
          title: `Are you sure you want to delete "${prompt.prompt}"?`,
          text: `This action cannot be undone.`,
          buttons: [
            {
              title: "Cancel",
              class: "secondary"
            },
            {
              title: "Delete Prompt",
              handler: async modal => {
                this.markSaved(false);
                await this.deletePrompt(prompt);
                this.markSaved(true);
                modal.$emit("close");
              }
            }
          ]
        });
      } else {
        this.markSaved(false);
        await this.deletePrompt(prompt);
        this.markSaved(true);
      }
    },
    async addPrompt() {
      await this.createPrompt({
        eventId: this.event.id
      });
    },
    copyLink: function() {
      try {
        copyTextToClipboard(this.eventLinkFull);
        this.$modal.show(AppModal, {
          title: "Event link copied to clipboard",
          htmlText: `<a href="${this.eventLinkFull}" target="_blank">${this.eventLinkFull}</a>`
        });
      } catch (err) {
        this.$modal.show(AppModal, {
          title: "Error copying event link.",
          text: err.message
        });
      }
    },
    async onDeleteEventClicked() {
      const eventName = this.event.name ? `"${this.event.name}"` : "this event";
      this.$modal.show(AppModal, {
        title: `Are you sure you want to delete ${eventName}?`,
        text: `This action cannot be undone.`,
        buttons: [
          {
            title: "Cancel",
            class: "secondary"
          },
          {
            title: "Delete Event",
            handler: async modal => {
              await this.removeEvent(this.event.id);
              modal.$emit("close");
              this.$router.push(`/events`);
            }
          }
        ]
      });
    }
  }
};
</script>
