<template>
  <router-link :to="primaryActionLink" class="event-list-item row">
    <div class="col-md-8 col-xs-11">
      <div class="event-name">{{ event.name }}</div>
      <div class="instruction">
        Date Created: {{ formatDate(event.createdAt) }}
      </div>
    </div>
    <div class="event-actions hidden-xl hidden-lg hidden-md col-xs-1">
      &rsaquo;
    </div>
    <div class="event-actions col-md-4 end-xs hidden-sm hidden-xs">
      <router-link
        v-if="canEdit"
        :to="editLink"
        class="btn tertiary"
        aria-label="Edit this event"
        @click.stop
      >
        Edit
      </router-link>
      <router-link
        v-if="canModerate"
        :to="moderateLink"
        class="btn secondary"
        aria-label="Moderate this event"
        @click.stop
      >
        Moderate
      </router-link>
      <router-link
        v-else-if="canView"
        :to="watchLink"
        class="btn secondary"
        aria-label="Observe this event"
        @click.stop
      >
        Watch
      </router-link>
    </div>
  </router-link>
</template>

<style lang="scss">
@import "../../styles/variables";
.event-list-item {
  padding: 25px 0;
  border-bottom: 1px solid $line-color;
  align-items: center;
  text-decoration: none;
  color: $copy;

  .event-name {
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>

<script>
import { hasEventRole } from "../../api/events";
import { EVENT_ROLES } from "../../../functions/lib/constants";

export default {
  props: ["event", "user"],
  computed: {
    canEdit() {
      return hasEventRole(this.user, this.event, [EVENT_ROLES.OWNER]);
    },
    canModerate() {
      return hasEventRole(this.user, this.event, [
        EVENT_ROLES.OWNER,
        EVENT_ROLES.MODERATOR
      ]);
    },
    canView() {
      return hasEventRole(this.user, this.event, [
        EVENT_ROLES.OWNER,
        EVENT_ROLES.MODERATOR,
        EVENT_ROLES.VIEWER
      ]);
    },
    editLink() {
      return `/${this.event.id}/edit`;
    },
    moderateLink() {
      return `/${this.event.id}/moderate`;
    },
    watchLink() {
      return `/${this.event.id}/watch`;
    },
    primaryActionLink() {
      if (this.canEdit) {
        return this.editLink;
      } else if (this.canModerate) {
        return this.moderateLink;
      } else if (this.canView) {
        return this.watchLink;
      }
      return "#";
    }
  },
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US");
    }
  }
};
</script>
